.audioPlayer_container {
  width: 100%;
  padding: 1em;
  background-color: black;
  border-top: 1px solid rgb(32, 32, 32);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 260px;
}
.audioPlayer_controllers {
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2em;
}
.audioPlayer_controller {
  width: 2em !important;
  font-size: 1.5em;
  cursor: pointer;
}
.audioPlayer_controller:hover {
  opacity: 0.5;
}
.audioPlayer_controller:active {
  opacity: 1;
}
.audioPlayer_controller-play {
  @extend .audioPlayer_controller;
  font-size: 2.5em;
}
.audio_volume {
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
  max-width: 7em;
}
.audio_volume_container {
  width: 100%;
  background-color: rgb(78, 78, 78);
  height: 5px;
  cursor: pointer;
}

.audio_volume_inner {
  background-color: red;
  width: 50%;
  height: 100%;
}
.audio_progress_container {
  width: 100%;
  background-color: rgb(78, 78, 78);
  height: 5px;
  cursor: pointer;
}
.audio_progress_inner {
  background-color: red;
  height: 100%;
  pointer-events: none;
}
.audioPlayer_icon {
  @extend .audioPlayer_controller;
  font-size: 1.8em;
  cursor: pointer;
  opacity: 0.5;
  position: relative;
}
.audioPlayer_icon:hover {
  opacity: 1;
}
.audioPlayer_icon:active {
  opacity: 0.5;
}
.audioPlayer_icon-active {
  @extend .audioPlayer_icon;
  opacity: 1;
}
.audioPlayer_icon_one {
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
  position: absolute;
  background-color: black;
  border-radius: 50%;
  pointer-events: none;
}
.audioPlayer_icon_repeat {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.audio_progress {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-top: 1em;
}
.audioPlayer_albumImage {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 20%;
  min-width: 7em;
}
.audioPlayer_albumTitle h3 {
  font-size: 0.8em;
}
.audioPlayer_albumTitle {
  position: absolute;
  left: 6em;
  width: 15%;
}
.audioPlayer_albumImage img {
  width: 4em;
}
@media only screen and (max-width: 800px) {
  .audioPlayer_closeBtn {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .audioPlayer_container {
    justify-content: space-evenly;
    gap: 1em;
  }
  .audioPlayer_albumImage {
    display: none;
  }
}
@media only screen and (max-width: 570px) {
  .audioPlayer_controller {
    font-size: 1em;
  }
  .audioPlayer_controller-play {
    font-size: 1.5em;
  }
}
@media only screen and (max-width: 450px) {
  .audioPlayer_controllers {
    gap: 1em;
  }
}
@media only screen and (max-width: 360px) {
  .audio_volume_container {
    display: none;
  }
  .audio_volume {
    width: unset;
  }
  .audioPlayer_controllers {
    gap: 0.5em;
  }
}
@media only screen and (max-width: 300px) {
  .audioPlayer_controllers {
    gap: 0.2em;
  }
}
